import styled from 'styled-components';
import { colors } from '../../constants';
import { IArtwork } from '../../types';

const Lot = styled.div``;

interface IProps {
    artwork: IArtwork;
    className?: string;
    lazyLoad: boolean;
    onClick: () => void;
}

const BaseArtwork = ({ artwork, className, lazyLoad, onClick }: IProps) => (
    <li className={className} onClick={onClick}>
        <img src={artwork.image} loading={lazyLoad ? 'lazy' : 'eager'} />
        <Lot>{artwork.lotNumber}</Lot>
    </li>
);

export const Artwork = styled(BaseArtwork)`
    position: relative;
    height: 350px;
    width: 350px;
    margin: 8px;
    cursor: pointer;

    ${Lot} {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        bottom: 10px;
        right: 10px;
        z-index: 1;
        font-weight: 800;
        background-color: ${colors.white};
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;
