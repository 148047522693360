import styled from 'styled-components';

import { breakpoints, colors } from '../../constants';

export const Button = styled.button`
    appearance: none;
    display: block;
    padding: 20px 70px;
    font-family: inherit;
    text-align: center;
    color: ${colors.white};
    background-color: ${colors.black};
    border: 0;
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 0.15s ease;

    :focus {
        outline: 0;
    }

    @media (${breakpoints.mediumMin}) {
        :hover {
            transform: translate(0, -3px);
        }
    }
`;
