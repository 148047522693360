import React from 'react';
import styled from 'styled-components';

interface IProps {
    className?: string;
    onClick?(): void;
}

const BaseClose: React.FC<IProps> = ({ className, onClick }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        onClick={onClick}
    >
        <path
            stroke="#fff"
            strokeWidth="12"
            strokeLinecap="square"
            d="M 10,10 90,90 M 10,90 90,10"
        />
    </svg>
);

export const Close = styled(BaseClose)``;
