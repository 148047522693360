import styled from 'styled-components';

import InnerImageZoom from 'react-inner-image-zoom';
import { colors } from '../../constants';
import { Close } from '../../svg';
import { IArtwork } from '../../types';
import { Container } from '../Container';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

const ImageContainer = styled.div``;
const TextContainer = styled.div``;
const Details = styled.ul``;
const Description = styled.div``;

interface IProps {
    className?: string;
    artwork: IArtwork;
    onCloseButtonClick: () => void;
}

const width = window.innerWidth;

const BaseArtworkOverlay = ({
    artwork,
    className,
    onCloseButtonClick,
}: IProps) => (
    <div className={className}>
        <Close onClick={onCloseButtonClick} />
        <Container>
            <ImageContainer>
                <InnerImageZoom
                    src={artwork.image}
                    hasSpacer
                    hideCloseButton
                    hideHint
                    zoomPreload
                    width={width < 900 ? 420 : 900}
                    height={420}
                />
            </ImageContainer>
            <TextContainer>
                <Details>
                    <h2>
                        {artwork.title}
                        <br />({artwork.artist})
                    </h2>
                    <li>Lotnummer: {artwork.lotNumber}</li>
                    <li>Afmetingen: {artwork.dimensions} cm</li>
                    <li>Materiaal: {artwork.details}</li>
                    <li>Startbod: € {artwork.startingBid}</li>
                </Details>
                {artwork.description && (
                    <Description>
                        <h3>Omschrijving</h3>
                        {artwork.description}
                    </Description>
                )}
                {artwork.artistDescription && (
                    <Description>
                        <h3>Over {artwork.artist}</h3>
                        {artwork.artistDescription}
                    </Description>
                )}
            </TextContainer>
        </Container>
    </div>
);

export const ArtworkOverlay = styled(BaseArtworkOverlay)`
    position: fixed;
    overflow-y: scroll;
    padding: 20px;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: ${colors.black};
    color: ${colors.white};
    line-height: 1.2;

    ${Close} {
        position: absolute;
        right: 20px;
        height: 26px;
        cursor: pointer;
        fill: ${colors.white};
    }

    ${TextContainer}, ${ImageContainer} {
        flex: 1;
    }

    ${ImageContainer} {
        width: 100%;
        height: 500px;
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2 {
        margin-bottom: 10px;
    }

    img {
        object-fit: contain;
    }

    ${Details}, ${Description} {
        padding-bottom: 30px;
    }
`;
