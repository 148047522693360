import styled from 'styled-components';

interface IProps {
    color: string;
}

export const Circle = styled.div<IProps>`
    background-color: ${({ color }) => color};
    border-radius: 50%;
`;
