import { IArtwork } from '../types';
import affairs from '../images/galerielennie/affairs.jpg';
import aureum from '../images/galerielennie/aureum.jpg';
import braStrap from '../images/galerielennie/braStrap.jpg';
import deadline from '../images/galerielennie/deadline.jpg';
import blueSky from '../images/galerielennie/blueSky.jpg';
import homerKikkert from '../images/galerielennie/homerKikkert.jpg';
import musician from '../images/galerielennie/musician.jpg';
import kat441121 from '../images/galerielennie/kat441121.jpg';
import livingRoomVisitors from '../images/galerielennie/livingRoomVisitors.jpg';
import lostinSpace4 from '../images/galerielennie/lostinSpace4.jpg';
import sanWyfkes from '../images/galerielennie/sanWyfkes.jpg';
import spirit4 from '../images/galerielennie/spirit4.jpg';
import steenkistWerk1 from '../images/galerielennie/steenkistWerk1.jpg';
import stuyvesant from '../images/galerielennie/stuyvesant.jpg';
import vaderDraagtZoon from '../images/galerielennie/vaderDraagtZoon.jpg';
import humizovo from '../images/artcatch/4dunaevaHumizovo.jpg';
import noon from '../images/artcatch/14velichkoNoon.jpg';
import untitledNoreen from '../images/galerielennie/untitled-noreen.jpg';
import lostInSpace2 from '../images/galerielennie/lostInSpace2.jpg';
import werk9 from '../images/galerielennie/steenkistWerk9.jpg';
import limeRhapsody from '../images/galerielennie/limerhapsody.jpg';

export const artworks: IArtwork[] = [
    {
        id: 0,
        lotNumber: 1,
        title: 'Sân Wyfkes',
        artist: 'Gerke Procee',
        image: sanWyfkes,
        startingBid: 880,
        description:
            'Gerke is al in 2016 begonnen aan Sân Wyfkes, maar heeft het toen aan de kant gezet met de intentie om het later af te maken. Hij heeft het werk toen ergens opgeborgen en vond het in 2021 weer terug. Hij voelde toen direct de urgentie om Sân Wyfkes de inhoud en perceptie mee te geven die hij voor ogen had. In de tijd dat hij het had opgeborgen heeft Gerke er waarschijnlijk onbewust over nagedacht hoe hij dit werk de juiste vorm en uitstraling kon geven. Het waren eerst wat abstracte vormen die uiteindelijk zeven vrouwelijke figuren zijn geworden.',
        artistDescription: `Gerke Procee (1993) woont in Friesland. Hij is in 2020 afgestudeerd voor Autonome Beeldende Kunst aan de Academie Minerva in Groningen.Door een onderdompeling in een filosofisch doolhof van archetypen, griezeligheid en het unheimliche, is Gerke veel van zijn diepste angsten en fantasieën gaan herkennen. Gerke's werk is een creatieve explosie waarin hij nooit ontwikkelde creaties onderzoekt. Deze legt hij vervolgens vast op digitaal en fysiek canvas.`,
        dimensions: '100 x 100 cm',
        details: 'Acrylverf op canvas',
    },
    {
        id: 1,
        lotNumber: 2,
        title: 'Homer de kikkert slikkert',
        artist: 'Gerke Procee',
        image: homerKikkert,
        startingBid: 700,
        description:
            'Homer de kikkert slikkert is een compilatie van Homer Simpson die vreemde hallucinerende visioenen krijgt als hij aan een kikker likt en pillen tot zich neemt. Hij raakt hierdoor in een andere wereld waar hij zichzelf in zijn favoriete voedsel ziet veranderen en het toch niet kan laten om zichzelf even te gaan proeven. Tijdens een bad trip komt hij terecht in de jas van zijn baas die hem altijd op de huid zit. In Homer de kikkert slikkert wil Gerke de invloeden van cartoons op de maatschappij en de invloed van de maatschappij op cartoons uitbeelden.',
        artistDescription: `Gerke Procee (1993) woont in Friesland. Hij is in 2020 afgestudeerd voor Autonome Beeldende Kunst aan de Academie Minerva in Groningen.Door een onderdompeling in een filosofisch doolhof van archetypen, griezeligheid en het unheimliche, is Gerke veel van zijn diepste angsten en fantasieën gaan herkennen. Gerke's werk is een creatieve explosie waarin hij nooit ontwikkelde creaties onderzoekt. Deze legt hij vervolgens vast op digitaal en fysiek canvas.`,
        dimensions: '69 x 66 cm',
        details: 'Acrylverf op houten paneel ',
    },
    {
        id: 2,
        lotNumber: 3,
        title: 'Werk 9',
        artist: 'Jesse Steenkist',
        image: werk9,
        startingBid: 240,
        description:
            'De paarse plastic zak lag op straat. De zak trok Jesse haar aandacht. Het is een dun tasje die snel kapot gaat. Dit stuk zal voor altijd heel blijven. Locatie van de vondst: Utrecht centrum',
        artistDescription: `Jesse Steenkist woont en werkt in Utrecht. Jesse heeft een fascinatie voor het alledaagse. Haar oog valt op felle kleuren en vormen tijdens een wandeling/fietsroute. Iets onnatuurlijks in de natuur. De manier waarop ik werk voelt als een spel van bouwen en wegnemen. De kleinste verandering van vorm of kleur kan de totaliteit van het werk veranderen. Het proces ziet ze als een reis waarin ze het werk meeneemt. Soms is het een korte reis waarin elke beweging past bij de volgende en soms is het een complexe reis van veranderingen en "verkeerde" bewegingen.`,
        dimensions: '30 x 30 cm',
        details: 'Hout, Plastic, Papier , Textiel, draad, verf',
    },
    {
        id: 3,
        lotNumber: 4,
        title: 'Lost in space 4',
        artist: 'Jacobien de Korte',
        image: lostinSpace4,
        startingBid: 700,
        description: 'Eenzaamheid in een stedelijke omgeving.',
        artistDescription:
            'Jacobien de Korte (1969) heeft grafische vormgeving gestudeerd aan de Academie van beeldende kunsten in Rotterdam (Willem de Kooning). Haar autonoom werk ontstaat vanuit persoonlijke gevoelens en ervaringen. Op verstilde, introverte wijze tracht ze begrippen uit te drukken als vervreemding, eenzaamheid, mysterie en melancholie. Deze zijn voor iedereen in meer of mindere mate herkenbaar. Met het streven naar wederzijdse herkenbaarheid hoopt ze troost en begrip te bieden en te vinden. Het printen van het werk op fine art papier is hierbij een belangrijk onderdeel van het proces.',
        dimensions: '90 x 60 cm',
        details: 'Fotografie op Dibond',
    },
    {
        id: 4,
        lotNumber: 5,
        title: 'Werk 1',
        artist: 'Jesse Steenkist',
        image: steenkistWerk1,
        startingBid: 340,
        description:
            'Een doorzichtige plastic lag in de vuilnisbak bij iemand voor de deur. De plastic zak zat in een vuilniszak, samen in elkaar. Het touwtje van de vuilniszak en de plastic zak zijn ook samen gelamineerd. Locatie van de vondst: wijk in Amsterdam',
        artistDescription:
            'Jesse Steenkist woont en werkt in Utrecht. Jesse heeft een fascinatie voor het alledaagse. Haar oog valt op felle kleuren en vormen tijdens een wandeling/fietsroute. Iets onnatuurlijks in de natuur. De manier waarop ik werk voelt als een spel van bouwen en wegnemen. De kleinste verandering van vorm of kleur kan de totaliteit van het werk veranderen. Het proces ziet ze als een reis waarin ze het werk meeneemt. Soms is het een korte reis waarin elke beweging past bij de volgende en soms is het een complexe reis van veranderingen en "verkeerde" bewegingen.',
        dimensions: '30 x 30 cm',
        details: 'Hout, Plastic, Papier , Textiel, Draad, Verf',
    },
    {
        id: 5,
        lotNumber: 6,
        title: 'Untitled #1',
        artist: 'Anastasia Dunaeva',
        image: humizovo,
        startingBid: 200,
        description:
            'Dit schilderij onderzoekt vorm, kleur en ruimte. Veel van de canvasschilderijen van Anastassia zijn voorbereidingen voor haar street-art.',
        artistDescription: `Anastasia Dunaeva (1998) is schilder en digitaal kunstenaar. Ze onderzoekt graffiti als medium en cultureel fenomeen. Door haar street-art creëert ze plaatsgebonden abstracte schilderijen in de openbare ruimte die een voorwerp vormen voor haar verdere experimenten met beelden in een digitaal format. Haar werk bevat kenmerken van street-art zoals een interactie met de ruimte, vergankelijkheid, marginaliteit en fysieke uitdagingen. Daarnaast vertoont haar praktijk duidelijke kenmerken van hedendaagse kunst. Zodoende kan het werk nauwkeuriger omschreven worden als "street painting". Anastasia is afgestudeerd in Art & Design aan de Higher School of Economics en is student aan het Instituut voor Hedendaagse Kunst in Moskou. Haar werken zijn te zien geweest in vele openbare ruimtes in Moskou. In 2021 lanceerde Electrozavod Gallery haar persoonlijke site-specific show.`,
        dimensions: '40 x 40 cm',
        details: 'Mixed media op canvas',
    },
    {
        id: 6,
        lotNumber: 7,
        title: 'Sticky Blue Sky',
        artist: 'Florencia Rojas',
        image: blueSky,
        startingBid: 450,
        description: `Bij de Sticky-serie draait alles om extreme textuur, kleine details en een elastische agglutinerende afwerking. Dit kneedbare materiaal brengt de beweging over die wordt gedestilleerd door de beweging van mijn hand. Dit is een andere serie binnen het concept van gebaar dat al mijn huidige en eerdere werk heeft gedefinieerd. De overheersende kleur voor deze collectie is pastels, want wie houdt er nu niet van pastels.`,
        artistDescription: `Florencia Rojas is de kunstenaar achter het merk 'Canach'. Dit merk creëert gestuele hedendaagse kunst met een opvallend gedurfd karakter. Florencia poogt zich door middel van haar werk volledig uit te drukken zonder enig oordeel. Ze herschept haar gevoelens en creatieve voorstellingen ontsproten aan haar fantasierijke geest. De werken van Florencia hebben geen minimalistische maar veeleer een 'maximalistische' expressie. Ze legt de nadruk op het creëren van verschillende dimensies en doet dat altijd met monochromatische kleurtinten. Als gevolg hiervan kan de kijker vormen en schaduwen verkennen die steeds veranderen afhankelijk van verlichting, perspectief of iemands stemming en perceptie.`,
        dimensions: '27 x 34 cm',
        details: 'Mixed Media on Canvas',
    },
    {
        id: 7,
        lotNumber: 8,
        title: 'Kat 441121',
        artist: 'Annelise Hewitt',
        image: kat441121,
        startingBid: 400,
        description:
            'Dit kunstwerk is gemaakt van reclamemateriaal met een kat uit de jaren 80.',
        artistDescription:
            'Annelise Hewitt is een opkomende Afro-Amerikaanse beeldend kunstenaar. Sinds 2018 is ze professioneel actief zowel in Nederland als in de Verenigde Staten. Annelise behaalde een bachelordiploma aan het Williams College (Williamstown, Massachusetts, US) en ontving later een masterdiploma van de Vrije Universiteit in Amsterdam. Annelise woont en werkt sinds 2016 voornamelijk in Nederland en spreekt de Nederlandse taal onderhand vloeiend. Hewitt maakt al haar kunst met de hand en uitsluitend van afgedankte materialen. Duurzaamheid is een belangrijk kenmerk in al haar werk. Dit komt tot uiting door het gebruik van afgedankte of in onbruik geraakte reisgidsen, tijdschriften en andere recyclebare materialen.',
        dimensions: '40 x 60 cm',
        details: 'Papier en acryl op papier',
    },
    {
        id: 8,
        lotNumber: 9,
        title: 'Stuyvesant 44451',
        artist: 'Annelise Hewitt',
        image: stuyvesant,
        startingBid: 400,
        description:
            'Dit kunstwerk is gemaakt van Peter Stuyvesant reclamemateriaal uit de jaren 80.',
        artistDescription:
            'Annelise Hewitt is een opkomende Afro-Amerikaanse beeldend kunstenaar. Sinds 2018 is ze professioneel actief zowel in Nederland als in de Verenigde Staten. Annelise behaalde een bachelordiploma aan het Williams College (Williamstown, Massachusetts, US) en ontving later een masterdiploma van de Vrije Universiteit in Amsterdam. Annelise woont en werkt sinds 2016 voornamelijk in Nederland en spreekt de Nederlandse taal onderhand vloeiend. Hewitt maakt al haar kunst met de hand en uitsluitend van afgedankte materialen. Duurzaamheid is een belangrijk kenmerk in al haar werk. Dit komt tot uiting door het gebruik van afgedankte of in onbruik geraakte reisgidsen, tijdschriften en andere recyclebare materialen.',
        dimensions: '40 x 60 cm',
        details: 'Papier, chirurgische mondkapjes en acryl op papier',
    },
    {
        id: 9,
        lotNumber: 10,
        title: 'Aureum',
        artist: 'Iris van der Harst',
        image: aureum,
        startingBid: 520,
        description: '',
        artistDescription:
            'Iris van der Harst (1993) is een kunstschilder uit het noorden van Nederland die met olieverf de grenzen opzoekt binnen het zelfportretteren en creëren van stillevens. Het eren van de stilte van alledaagse momenten staat centraal, waarbij elementen van vervreemding, isolatie, intimiteit, kijken en bekeken worden het meest spreken. Nu meer dan ooit zijn we als mens aangewezen op onze capaciteit om te kunnen leven in isolatie. Met het aannemen van verschillende rollen - aan beide kanten van het doek - creëert Iris continu nieuwe perspectieven waarmee ze een werk op intrigerende wijze gestalte geeft.',
        dimensions: '40 x 50 cm',
        details: 'Olieverf op canvasboard',
    },
    {
        id: 10,
        lotNumber: 11,
        title: 'Deadline',
        artist: `Kamilė Česnavičiūtė`,
        image: deadline,
        startingBid: 800,
        description:
            'Het is een late avond. Buiten is het donker en binnen is er gedimd werklicht. Steeds als je denkt dat je klaar bent besef je dat je nog net iets meer werk te doen hebt. Tot daadwerkelijk het laatste moment aanbreekt. Iets leid je af…',
        artistDescription: `De in Litouwen geboren kunstenares Kamilė Česnavičiūtė (1996) woont en werkt in Nederland. Kamilė studeerde na de middelbare school beeldende kunst aan de kunstacademie Minerva te Groningen. Na haar afstuderen legde Kamilė zich toe op het schilderen.Haar jonge carrière kent reeds een aantal bijzondere prestaties. In 2019 behoorde ze tot de 'Best of Graduates' tentoongesteld bij galerie Ron Mandos te Amsterdam. Vervolgens verkreeg ze een residentie in Het Resort. Ze won de Sluijters prijs voor schilderen en nam deel aan diverse groepstentoonstellingen en kunstenaarssamenwerkingen, zoals het corona-geïnspireerde online kunst- en muziekplatform @homescreen._ op Instagram.`,
        dimensions: '73 x 103 cm',
        details: 'Acryl op canvas ',
    },
    {
        id: 11,
        lotNumber: 12,
        title: 'Living room visitors',
        artist: `Kamilė Česnavičiūtė`,
        image: livingRoomVisitors,
        startingBid: 400,
        description:
            'Gezichten en handen zijn één van mijn favoriete dingen om te tekenen en te schilderen wanneer ik nieuwe onderwerpen probeer te bedenken. In dit werk komen ze op bezoek. Het was simpelweg een aangenaam bezoek, waarbij ik slechts een glimp van één gezicht opving.',
        artistDescription: `De in Litouwen geboren kunstenares Kamilė Česnavičiūtė (1996) woont en werkt in Nederland. Kamilė studeerde na de middelbare school beeldende kunst aan de kunstacademie Minerva te Groningen. Na haar afstuderen legde Kamilė zich toe op het schilderen.Haar jonge carrière kent reeds een aantal bijzondere prestaties. In 2019 behoorde ze tot de 'Best of Graduates' tentoongesteld bij galerie Ron Mandos te Amsterdam. Vervolgens verkreeg ze een residentie in Het Resort. Ze won de Sluijters prijs voor schilderen en nam deel aan diverse groepstentoonstellingen en kunstenaarssamenwerkingen, zoals het corona-geïnspireerde online kunst- en muziekplatform @homescreen._ op Instagram.`,
        dimensions: '65 x 55 cm',
        details: 'Acryl op canvas',
    },
    {
        id: 12,
        lotNumber: 13,
        title: 'Passionate musician',
        artist: 'Noreen Riepma',
        image: musician,
        startingBid: 480,
        description:
            'Dit werk geeft de worsteling en de paranoia van de mentale staat van de mens weer als een gedachtestroom en een continu overprikkeld brein. Naast de gedachtestroom, continue indrukken, gevoelens en ideeën die de menselijke hersenen dagelijks opslaan, bestaat er ook een intieme lichamelijke verbintenis. Soms lijkt alle informatie even niet in je hoofd te passen. Maar het besef dat er miljoenen mensen zijn die net als jij met vergelijkbare emoties, informatie en indrukken worstelen kan mensen ook weer samenbrengen, zowel mentaal als fysiek.',
        artistDescription:
            'Noreen Riepma is een kunstenares met museale potentie. Ze maakt op dit moment wandvullende houtskooltekeningen op grote vellen papier. De Nederlandse en Pakistaanse opvoeding van Noreen heeft een grote invloed gehad op haar werk. Ook het leven in verschillende landen zoals Engeland en Ierland heeft haar werk beïnvloed. Als kunstenaar wil ze seksuele taboes onder de aandacht brengen en doorbreken, belangrijke seksuele en gender gerelateerde kwesties vertegenwoordigen en de donkerste waarheden omtrent de menselijke mentale staat het onder ogen komen.',
        dimensions: '42 x 33 cm',
        details: 'Tekening op papier',
    },
    {
        id: 13,
        lotNumber: 14,
        title: 'Affairs in Maakhaven',
        artist: 'Just Alex',
        image: affairs,
        startingBid: 500,
        description: '',
        artistDescription: `Alex zoekt een middenweg in de lyrische spanning die heerst tussen enerzijds de niet-aflatende drang om betekenis te geven en anderzijds de even onverzettelijke aandrang van het toeval. Deze spanning is de vibratie of misschien beter gezegd de dissonantie die de alledaagse ervaring bepaalt. Op het moment dat Alex deze paradox weergeeft in een materieel proces - een beeld dat wordt geconfronteerd met de blik van een toeschouwer - vindt er een ontmoeting plaats met de 'liminaliteit'. Dit is het moment waarop een toeschouwer die een beeld zonder impliciete of expliciete betekenis bestudeert, komt tot een interpretatie.`,
        dimensions: '60 x 70 cm',
        details: 'Oil on canvas',
    },
    {
        id: 14,
        lotNumber: 15,
        title: 'Vader draagt zijn zoon in zijn armen',
        artist: 'Noreen Riepma',
        image: vaderDraagtZoon,
        startingBid: 400,
        description:
            'De tekening laat een Afghaanse vluchteling zien op het strand.',
        artistDescription: `"Noreen Riepma is een kunstenares met museale potentie. Ze maakt op dit moment wandvullende houtskooltekeningen op grote vellen papier.
        De Nederlandse en Pakistaanse opvoeding van Noreen heeft een grote invloed gehad op haar werk. Ook het leven in verschillende landen zoals Engeland en Ierland heeft haar werk beïnvloed.
        Als kunstenaar wil ze seksuele taboes onder de aandacht brengen en doorbreken, belangrijke seksuele en gender gerelateerde kwesties vertegenwoordigen en de donkerste waarheden omtrent de menselijke mentale staat het onder ogen komen."`,
        dimensions: '21 x 29,7 cm',
        details: 'Potlood op papier',
    },
    {
        id: 15,
        lotNumber: 16,
        title: 'Spirit 4',
        artist: 'Jacobien de Korte',
        image: spirit4,
        startingBid: 600,
        description:
            'De recent gemaakte serie “Spirit” is ontstaan om verhalen van anderen/ de afgebeelde op mijn eigen wijze uit te beelden. Allen staan op een punt in hun leven waarbij zij vragen stellen, keuzes of zelfs wending maken. Hiervoor is moed nodig. Door deze belangrijke gebeurtenissen vast te leggen in beeld, wil ik ze kracht geven. ',
        artistDescription:
            'Jacobien de Korte (1969) heeft grafische vormgeving gestudeerd aan de Academie van beeldende kunsten in Rotterdam (Willem de Kooning). Haar autonoom werk ontstaat vanuit persoonlijke gevoelens en ervaringen. Op verstilde, introverte wijze tracht ze begrippen uit te drukken als vervreemding, eenzaamheid, mysterie en melancholie. Deze zijn voor iedereen in meer of mindere mate herkenbaar. Met het streven naar wederzijdse herkenbaarheid hoopt ze troost en begrip te bieden en te vinden. Het printen van het werk op fine art papier is hierbij een belangrijk onderdeel van het proces.',
        dimensions: '60 x 40 cm',
        details: 'Fotografie op Dibond',
    },
    {
        id: 16,
        lotNumber: 17,
        title: 'Middag',
        artist: 'Alexander Velichko',
        image: noon,
        startingBid: 400,
        description:
            'Alexander speelt in zijn werken graag met schaal. Hij is geïnteresseerd in het metafysische in het banale, het gehle universum manifest in één klein alledaags ding. Water wordt nogal eens voorgesteld als een symbool van bezinning, wedergeboorte en zuivering, zo ook in de schilderijen en het grafisch werk van Alexander. De wereldberoemde filmregisseur Andrey Tarkovski, naar wie Velichko vaak verwijst, zei: "Water is een mystieke substantie, waarvan elk molecuul bijzonder fotogeniek is. Het kan een bepaalde actie overbrengen, een gevoel van variabiliteit en de continuïteit van beweging."',
        artistDescription: `Alexander Velichko studeerde af aan de National Art Academy of Lviv (Oekraïne). Alexey exposeerde eerder in museum- en galerieruimtes in Rusland, zoals het Staatsmuseum van Hedendaagse Geschiedenis van Rusland (Moskou), het Russisch Museum (St.Petersburg) en het Russisch Museum voor Decoratieve Kunst (Moskou). Na enkele jaren als grafisch ontwerper te hebben gewerkt, wijdt hij zich nu volledig aan de schilderkunst. Velichko is gefascineerd door dit traditionele medium. Hij onderzoekt het vermogen om met schilderkunst zeer gevoelige boodschappen over te brengen vanuit een hedendaagse persepctief. De realistische manier waarop hij schildert is eerder bedoeld om de levendige beweging van het bestaan weer te geven, dan om gedetailleerd de waarheid na te bootsen. In Velichko's werken is er altijd een kloof tussen schoonheid en verval; de stilte voor de storm is voelbaar.`,
        dimensions: '21 x 28 cm',
        details: 'Grafiet op papier',
    },
    {
        id: 17,
        lotNumber: 18,
        title: 'Bra Strap',
        artist: 'Iris van der Harst',
        image: braStrap,
        startingBid: 700,
        description:
            'Bra strap is een geïsoleerd moment van Iris haar eigen handen. Net als bij Horror Vacui heeft Iris in Bra strap geëxperimenteerd met de witruimte en of dit een rol kan spelen in de zeggingskracht van het werk.',
        artistDescription:
            'Iris van der Harst (1993) is een kunstschilder uit het noorden van Nederland die met olieverf de grenzen opzoekt binnen het zelfportretteren en creëren van stillevens. Het eren van de stilte van alledaagse momenten staat centraal, waarbij elementen van vervreemding, isolatie, intimiteit, kijken en bekeken worden het meest spreken. Nu meer dan ooit zijn we als mens aangewezen op onze capaciteit om te kunnen leven in isolatie. Met het aannemen van verschillende rollen - aan beide kanten van het doek - creëert Iris continu nieuwe perspectieven waarmee ze een werk op intrigerende wijze gestalte geeft.',
        dimensions: '60 x 50 cm',
        details: 'Olieverf op canvasboard',
    },
    {
        id: 18,
        lotNumber: 19,
        title: 'Lost in space 2',
        artist: 'Jacobien de Korte',
        image: lostInSpace2,
        startingBid: 720,
        description: `Eenzaamheid in een stedelijke omgeving. Dit werk wordt aangeboden in een oplagen van 10 stuks. De werken worden gesigneerd verkocht, voorzien van een echtheidsverklaring en oplagennummer. Volgend oplagenummer is 1/10.`,
        artistDescription: `Jacobien de Korte (1969) heeft grafische vormgeving gestudeerd aan de Academie van beeldende kunsten in Rotterdam (Willem de Kooning). Haar autonoom werk ontstaat vanuit persoonlijke gevoelens en ervaringen. Op verstilde, introverte wijze tracht ze begrippen uit te drukken als vervreemding, eenzaamheid, mysterie en melancholie. Deze zijn voor iedereen in meer of mindere mate herkenbaar. Met het streven naar wederzijdse herkenbaarheid hoopt ze troost en begrip te bieden en te vinden. Het printen van het werk op fine art papier is hierbij een belangrijk onderdeel van het proces.`,
        dimensions: '90 x 60 cm',
        details: 'Op Dibond',
    },
    {
        id: 19,
        lotNumber: 20,
        title: 'Untitled',
        artist: 'Noreen Riepma',
        image: untitledNoreen,
        startingBid: 600,
        description:
            'Een omarming van de vrouwelijke seksualiteit: gewichtloosheid, delicaat en sensueel.',
        artistDescription:
            'Noreen Riepma is een kunstenares met museale potentie. Ze maakt op dit moment wandvullende houtskooltekeningen op grote vellen papier. De Nederlandse en Pakistaanse opvoeding van Noreen heeft een grote invloed gehad op haar werk. Ook het leven in verschillende landen zoals Engeland en Ierland heeft haar werk beïnvloed. Als kunstenaar wil ze seksuele taboes onder de aandacht brengen en doorbreken, belangrijke seksuele en gender gerelateerde kwesties vertegenwoordigen en de donkerste waarheden omtrent de menselijke mentale staat het onder ogen komen.',
        dimensions: '55 x 46 cm',
        details: 'Grafiet potlood op papier',
    },
    {
        id: 20,
        lotNumber: 21,
        title: 'Lime Rhapsody',
        artist: 'Just Alex',
        image: limeRhapsody,
        startingBid: 700,
        description: '',
        artistDescription: `Alex zoekt een middenweg in de lyrische spanning die heerst tussen enerzijds de niet-aflatende drang om betekenis te geven en anderzijds de even onverzettelijke aandrang van het toeval. Deze spanning is de vibratie of misschien beter gezegd de dissonantie die de alledaagse ervaring bepaalt. Op het moment dat Alex deze paradox weergeeft in een materieel proces - een beeld dat wordt geconfronteerd met de blik van een toeschouwer - vindt er een ontmoeting plaats met de 'liminaliteit'. Dit is het moment waarop een toeschouwer die een beeld zonder impliciete of expliciete betekenis bestudeert, komt tot een interpretatie.`,
        dimensions: '90 x 100 cm',
        details: 'Oil on canvas',
    },
];
