import { useRef, useState } from 'react';
import styled from 'styled-components';
import { artworks } from './data';
import { Container } from './components/Container';
import { Footer } from './components/Footer';
import { ArtworksList } from './components/ArtworksList';
import { ArtworkOverlay } from './components/ArtworkOverlay';
import { IArtwork } from './types';
import { Landing } from './components/Landing';
import { colors } from './constants';

interface IProps {
    className?: string;
}

const BaseApp = ({ className }: IProps) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [displayedArtwork, setDisplayedArtwork] = useState<IArtwork>(
        artworks[0]
    );
    const formRef = useRef<HTMLFormElement>(null);

    const handleArtworkClick = (id: number) => {
        setDisplayedArtwork(artworks[id]);
        setShowOverlay(true);
    };

    const handleButtonClick = () => {
        if (!formRef.current) return;

        formRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    if (showOverlay) {
        document.body.style.overflowY = 'hidden';
    } else {
        document.body.style.overflowY = 'scroll';
    }

    return (
        <div className={className}>
            {showOverlay && (
                <ArtworkOverlay
                    artwork={displayedArtwork}
                    onCloseButtonClick={() => setShowOverlay(false)}
                />
            )}
            <Landing onClick={handleButtonClick} />
            <Container>
                <ArtworksList
                    artworks={artworks}
                    onArtworkClick={handleArtworkClick}
                />
            </Container>
            <Footer formRef={formRef} />
        </div>
    );
};

export const App = styled(BaseApp)`
    background-color: ${colors.yellow};
`;
