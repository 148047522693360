import styled from 'styled-components';

import { Artwork } from '../Artwork';
import { Circle } from '../Circle';
import { IArtwork } from '../../types';
import { colors } from '../../constants';

const CircleTwo = styled(Circle)``;

interface IProps {
    artworks: IArtwork[];
    className?: string;
    onArtworkClick: (index: number) => void;
}

const BaseArtworksList = ({ artworks, className, onArtworkClick }: IProps) => (
    <div className={className}>
        <h2>CATALOGUS</h2>
        <Circle color={colors.green} />
        <ul>
            {artworks.map((artwork, index) => (
                <Artwork
                    artwork={artwork}
                    key={artwork.id}
                    lazyLoad={index > 2}
                    onClick={() => onArtworkClick(artwork.id)}
                />
            ))}
        </ul>
    </div>
);

export const ArtworksList = styled(BaseArtworksList)`
    position: relative;
    z-index: 1;

    h2 {
        text-align: center;
        padding: 0 40px;
        line-height: 1;
    }

    ul {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 40px;

        &:first-of-type {
            margin-bottom: 80px;
        }
    }

    ${Circle} {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        width: 100px;
        height: 100px;
        transform: translate(-50%, -10px);
    }

    ${CircleTwo} {
        width: 100px;
        height: 100px;
        top: -20px;
    }
`;
