export const colors = {
    black: '#000000',
    blue: '#7CA9C9',
    green: '#A7CD94',
    greenMessage: '#24896B',
    pink: '#D5AABA',
    red: '#E57954',
    white: '#FFFFFF',
    yellow: '#EEDE79',
};
