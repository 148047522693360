import styled from 'styled-components';
import { Container } from '../Container';
import { SubscribeForm } from '../SubscribeForm';
import { Circle } from '../Circle';
import { breakpoints, colors } from '../../constants';

import dotartLogo from '../../images/dotart-logo.png';
import catchprintLogo from '../../images/catchprint-logo.png';
import kartoffelLogo from '../../images/kartoffel-logo.png';
import vitanovaLogo from '../../images/vitanova-logo.png';
import artcatchLogo from '../../images/artcatch-logo.png';
import glLogo from '../../images/gl-logo.png';

interface IconContainerProps {
    height?: number;
}

const Credits = styled.div``;
const LogoContainer = styled.div``;
const IconContainer = styled.div<IconContainerProps>`
    height: ${({ height }) => (height ? `${height}px` : '54px')};
`;

interface IProps {
    className?: string;
    formRef: React.RefObject<HTMLFormElement>;
}

const BaseFooter = ({ className, formRef }: IProps) => (
    <div className={className}>
        <Container>
            <h2>MELD JE AAN</h2>
            <h3>Vul hieronder je gegevens in</h3>
            <SubscribeForm formRef={formRef} />
            <LogoContainer>
                <IconContainer>
                    <img src={dotartLogo} />
                </IconContainer>
                <IconContainer height={80}>
                    <img src={catchprintLogo} />
                </IconContainer>
                <IconContainer height={80}>
                    <img src={kartoffelLogo} />
                </IconContainer>
                <IconContainer>
                    <img src={vitanovaLogo} />
                </IconContainer>
                <IconContainer>
                    <img src={glLogo} />
                </IconContainer>
            </LogoContainer>

            <Credits>
                Deze veiling wordt mogelijk gemaakt door{' '}
                <a
                    href="https://galerielennie.nl"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Galerie Lennie
                </a>
                .
            </Credits>
        </Container>
        <Circle color={colors.pink} />
    </div>
);

export const Footer = styled(BaseFooter)`
    position: relative;
    text-align: center;
    line-height: 1.2;
    padding: 20px 20px 40px;
    margin-top: 40px;
    z-index: 1;

    h3 {
        font-weight: 300;
    }

    ${LogoContainer} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 40px;

        @media (${breakpoints.smallMin}) {
            justify-content: center;
        }
    }

    ${IconContainer} {
        margin: 10px;
    }

    img {
        height: 100%;
    }

    ${SubscribeForm} {
        margin: 0 auto;
        margin-bottom: 80px;
    }

    ${Credits} {
        line-height: 1.5;
        max-width: 500px;
        margin: 0 auto;
    }

    ${Circle} {
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        // Transform into semicircle
        width: 300px;
        height: 150px;
        border-radius: 150px 150px 0px 0px;
        opacity: 0.9;
    }
`;
