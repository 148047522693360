import { createGlobalStyle } from 'styled-components';
import { colors } from './constants';

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    
    html, body, #root {
        height: 100%;
    }

    body {
        font-family: 'Poppins', 'Lato', sans-serif;
        margin: 0;
        padding: 0;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    p, h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        color: ${colors.black};
        line-height: 0;
    }
`;
