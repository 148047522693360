import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import { useState } from 'react';
import { Button } from '../Button';
import { colors } from '../../constants';

const MessageBar = styled.div`
    height: 24px;
    color: ${colors.greenMessage};
    margin: 4px 0;
`;

interface IProps {
    className?: string;
    formRef: React.RefObject<HTMLFormElement>;
}

const BaseSubscribeForm = ({ className, formRef }: IProps) => {
    const [success, setSuccess] = useState(false);

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (success) {
            setSuccess(false);
        }

        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const userId = process.env.REACT_APP_EMAILJS_USER_ID;

        if (!formRef.current || !serviceId || !templateId || !userId) return;

        // Todo: Use formik and yup for validation - emailjs.send() accepts objects
        emailjs.sendForm(serviceId, templateId, formRef.current, userId).then(
            () => {
                setSuccess(true);
            },
            (error) => {
                console.log(error);
            }
        );

        formRef.current.reset();
    };

    return (
        <form onSubmit={sendEmail} className={className} ref={formRef}>
            <MessageBar>
                {success && 'Bedankt, uw aanmelding is ontvangen!'}
            </MessageBar>
            <input
                required
                type="text"
                name="name"
                placeholder="Naam"
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = 'Naam')}
            />
            <input
                required
                type="text"
                name="email"
                placeholder="E-mail"
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = 'E-mail')}
            />
            <input
                type="text"
                name="phone"
                placeholder="Telefoon (optioneel)"
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = 'Telefoon (optioneel)')}
            />
            <Button type="submit">AANMELDEN</Button>
        </form>
    );
};

export const SubscribeForm = styled(BaseSubscribeForm)`
    display: flex;
    flex-direction: column;
    max-width: 500px;

    input {
        height: 56px;
        margin-bottom: 5px;
        padding: 10px;
        font-size: 16px;
        border-style: none;

        :focus {
            outline: none;
        }
    }

    ${Button} {
        margin-top: 20px;
    }
`;
